@import "./mixins.scss";
@import "./fonts.scss";
@import "./themes.scss";
@import "./base.scss";

.app {
  background-position: center;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;

  .padding-0 {
    padding-right: 0;
    padding-left: 0;
  }

  .main {
    text-align: center;
    width: 100%;
    max-width: 80rem;

    *:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
    }

    .logo-container {
      padding: 4rem;

      .logo {
        font-weight: 500;
        font-size: 2.5rem;
      }

      .logo-first-word {
        width: 120vh;
        height: 63vh;
        opacity: 0.92;
        // border-radius: 28px;
        // background-color: #001e2b;
        padding: 4px;
        color: $text-color-white;
        font-size: 2.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.54px;
        text-transform: uppercase;
      }

      .about {
        padding: 1rem;
        color: $text-color-white;
        opacity: 0.8;
      }

      .cron-word {
        background-color: #001e2b;
        padding: 4px;
      }

      .logo-second-word {
        width: 120vh;
        height: 63vh;
        opacity: 0.92;
        padding: 8px;
        text-decoration: underline;
      }
    }

    .component-container {
      margin-top: 1%;
      .input-group {
        // border-radius: 8px;
        margin-top: 3%;
        // width: 100%;
        // background-color: #002d40;
        .cron-type {
          text-align: center;
          vertical-align: middle;
          line-height: 4.7rem;
          font-size: 1.7rem;
          color: white;
          opacity: 0.8;
        }

        @media only screen and (min-device-width: 320px) and (max-device-width: 765px) {
          .cron-type {
            display: none;
          }
        }

        .copy-btn {
          width: 100%;
          height: 5.188rem;
          border: solid 1px rgba(255, 255, 255, 0.36);
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          border-radius: 4px;
          font-size: 14px;
          background-color: transparent;
          color: white;
          opacity: 0.8;
        }

        @media only screen and (min-device-width: 320px) and (max-device-width: 765px) {
          .copy-btn {
            display: none;
          }
        }

        .dropdown {
          padding: 0% 4%;
          .dropdown-toggle {
            width: 100%;
            height: 5.188rem;
            border: solid 1px rgba(255, 255, 255, 0.36);
            background-color: #005275;
            border-radius: 4px;
            font-size: 14px;

            span {
              font-size: 14px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.82;
              padding: 3.5rem;
            }

            @media only screen and (max-width: 600px) {
              span {
                font-size: 12px;
              }
            }
          }

          @media only screen and (max-width: 600px) {
            .dropdown-toggle {
              height: 5.5rem;
              border-radius: 4px;
              border: solid 1px rgba(255, 255, 255, 0.2);
              background-color: rgba(0, 82, 117, 0.61);
            }
          }

          .dropdown-menu {
            margin-top: 6px;
            width: 92%;
            height: 15rem;
            border-radius: 3px;
            border: solid 1px rgba(255, 255, 255, 0.43);
            background-color: #004461;
            overflow-y: scroll;

            button span {
              font-size: 14px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.82;
              letter-spacing: 0.4px;
              color: #ffffff;
            }

            @media only screen and (max-width: 600px) {
              button span {
                font-size: 12px;
              }
            }

            button:hover {
              background-color: unset !important;
              color: unset !important;
            }

            .active {
              background-color: rgba(0, 45, 64, 0.25);
            }

            .dropdown-item {
              padding: 1rem;
              text-align: center;
            }
          }
        }

        .expression {
          width: 100%;
          // border-top-right-radius: 8px;
          // border-bottom-right-radius: 8px;
          border-radius: 2px;
          overflow: hidden;
          // border-left: none;
          // border: white;
          // border-radius: 8px;

          input {
            height: 5rem;
            background-color: #002d40;
            font-size: 2.1rem;
            text-align: center;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.36px;
            color: #ffffff;
            word-spacing: 3rem;
            border: none;
          }

          @media only screen and (max-width: 600px) {
            input {
              font-size: 1.6rem;
            }
          }
        }

        @media only screen and (max-width: 600px) {
          .expression {
            display: inline-block;
            // width: 45rem;
            height: 5.5rem;
            border-radius: 4px;
            border: solid 1px rgba(255, 255, 255, 0.2);
            margin-top: 2rem;
          }

          input {
            height: 5rem;
            background-color: #002d40;
          }
        }

        .standard {
          border: solid 1px rgba(255, 255, 255, 0.36);
        }

        .error {
          border: 1px solid #eb5b5b;
        }
      }

      .input-info-wrapper {
        height: 4rem;

        .wildcards {
          // float: right;
          padding: 1.2rem;

          .btn {
            width: 7rem;
            height: 3rem;
            margin-left: 1rem;
            // border-radius: 28px;
            background-color: transparent;
            border: none;

            span {
              font-size: 1rem;
              font-weight: normal;
              font-stretch: normal;
              font-style: italic;
              color: #ffffff;
              opacity: 0.8;
            }
          }

          @media only screen and (max-width: 600px) {
            .btn {
              width: 7rem;
              margin-left: 0rem;
            }
          }

          .random-button {
            width: 7rem;
            height: 3rem;
            float: right;
            border-radius: 8px;
            margin-left: 2rem;
            border: solid 1px #fb7949;
            color: #fb7949;

            &:hover {
              border: solid 1px #fb7949;
              color: #fb7949;
            }
          }

          @media only screen and (min-device-width: 320px) and (max-device-width: 765px) {
            .random-button {
              display: none;
            }
          }

          .btn:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .btn.active {
            background-color: rgba(40, 163, 68, 0.45);
            span {
              color: #41f269;
            }
          }
        }
      }

      .syntax-info-container {
        margin-top: 6%;
      }

      .cron-info {
        margin-top: 7px;
        margin-left: 18%;
        width: 70%;
        // height: 5.2rem;
        background-color: transparent;
        display: flex;
        border-radius: 8px;

        .tag {
          width: 12rem;
          height: 5.2rem;
          border-radius: 8px;
          float: right;

          span {
            font-size: 1.9rem;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.6;
            letter-spacing: 0.54px;
            color: #ffffff;
          }

          @media only screen and (max-width: 600px) {
            span {
              font-size: 1.4rem;
              line-height: 5.4rem;
            }
          }

          @media only screen and (max-width: 350px) {
            span {
              font-size: 1.3rem;
              line-height: 5.4rem;
            }
          }
        }

        .meaning {
          border-radius: 8px;
          float: left;

          div {
            padding: 8px;
          }

          span {
            font-size: 1.6rem;
            text-align: center;
            margin: 1.3rem;
            font-weight: 300;
            text-overflow: ellipsis;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.3;
            letter-spacing: 0.36px;
            color: #ffffff;
          }

          @media only screen and (max-width: 600px) {
            span {
              font-size: 1.4rem;
              line-height: 2.6rem;
            }
          }

          @media only screen and (max-width: 350px) {
            span {
              font-size: 1.1rem;
              line-height: 3.2rem;
            }
          }
        }
      }

      @media only screen and (max-width: 600px) {
        .cron-info {
          margin-left: 16%;
        }
      }

      .cron-info:nth-child(odd) {
        background-color: rgba(2, 40, 57, 0.6);

        .tag {
          background-color: rgba(0, 30, 43, 0.61);
        }
      }

      .cron-info:nth-child(even) {
        background-color: transparent;

        .tag {
          background-color: rgba(0, 30, 43, 0.61);
        }
      }

      .cron-result {
        margin-top: 4%;
        width: 100%;
        overflow: auto;
        // height: 200px;
        // overflow: visible;
        clear: both;
        font-size: 2.8rem;
        font-weight: bold;
        font-stretch: normal;
        line-height: 1.82;
        letter-spacing: 0.61px;
        text-align: center;
        line-height: 3.9rem;
        padding: 3rem;

        .success {
          overflow: auto;
          color: #ffffff;
          font-style: italic;
        }

        .error {
          color: rgb(233, 50, 50);
        }
      }

      @media only screen and (max-width: 600px) {
        .cron-result {
          font-size: 2.3rem;
        }
      }
    }

    footer {
      margin-top: 8%;
      position: relative;
    }

    .social-links {
      a {
        margin: 10px;
        border: none;

        svg {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
        }
      }

      .github {
        svg {
          color: #126647;
          background-color: #00c47c;
        }
      }

      .linkedin {
        svg {
          background-color: rgba(74, 79, 90, 0.72);
          color: #b3b3b3;
        }
      }

      .fb {
        svg {
          background-color: #1196ff;
          color: #004e8b;
        }
      }

      .twitter {
        svg {
          background-color: #ff7551;
          color: #664f49;
        }
      }

      .instagram {
        svg {
          background-color: rgba(246, 10, 57, 0.46);
          color: #ff8aa1c9;
        }
      }
    }

    .copyrights {
      margin-top: 10px;
      font-size: 1.3rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.5;
      letter-spacing: 0.29px;
      color: #c3c3c3;
    }

    @media only screen and (max-width: 600px) {
      .social-links a {
        margin: 5px;
      }
    }

    .company-link {
      color: #c3c3c3;
    }

    @media only screen and (min-width: 765px) {
      .mobile-views {
        display: none;
      }
    }

    @media only screen and (max-width: 765px) {
      .mobile-views {
        margin: 8%;
        float: right;

        .random-button {
          width: 9rem;
          height: 3rem;
          float: right;
          border-radius: 8px;
          margin-left: 2rem;
          border: solid 1px #fb7949;
          color: #fb7949;
        }

        .random-button:hover {
          background-color: unset !important;
          color: #fb7949;
        }

        .copy-btn {
          width: 20rem;
          height: 3.188rem;
          border: solid 1px rgba(255, 255, 255, 0.36);
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          border-radius: 4px;
          font-size: 12px;
          background-color: transparent;
          color: white;
          opacity: 0.8;
          float: left;
        }
      }
    }
  }
}
