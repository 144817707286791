*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  font-family: 'Roboto', sans-serif;
  font-weight: 300;

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;

  @include respond(tab-port) {
    padding: 0;
  }
}

p {
  font-size: 1.6rem;
  line-height: 1.7rem;
  margin: 1rem;
}

::selection {
  background-color: #7cbcde;;
  color: $text-color-white;
}

input[type="button"] {
  border: none;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
